import { Component, OnInit } from '@angular/core';

import { GLOBAL } from './services/global';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from './services/user.service';
import { User } from './models/user';
import { Company } from './models/company';
import { filter } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { fadeIn } from '../assets/animation/router-animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fadeIn
  ]
})

export class AppComponent implements OnInit {
  title = 'GestOpp';
  public user: User;
  public company: Company;
  public companyId;
  public user_register: User;
  model: any = {};
  public identity;
  public token;
  public errorMessage;
  public alertRegister;
  public url: string;
  isLoginError = false;
  public navExpand;
  public class;
  public alertMessage;
  public pass = false;
  public currency;
  currentDay;
  dayOne;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private userService: UserService
  ) {
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    if (this.identity && this.token) {
      if (this.identity.company.settings.region) {
        const region = this.identity.company.settings.region;
        GLOBAL.currency = !region ? 'L' : region.currency;
      }
      this.currency = GLOBAL.currency;
      this.companyId = this.identity.company._id;
    }

    const today = new Date();
    this.currentDay = today.getDate() - 1;
    this.dayOne = today.getDate();
    console.log(this.currentDay - 1, this.identity);
    // this._router.navigate(['/login']);
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(res => {
      if (this._router.url === '/reportes') {
        this.navExpand = true;
        this.class = 'reportes';
      } else {
        this.navExpand = false;
        this.class = '';
      }
    });
  }
/*
  public login() {

          this._userService.login(this.model.username, this.model.password).subscribe(
            response => {
              const data = response.json();
              this.token = data.token;
              const user = JSON.stringify(data.user);

              if (this.token.length <= 0) {
                alert('el token no se ha generado');
              } else {

                localStorage.setItem('token', this.token);
                localStorage.setItem('user', user);
              }
            },
            error => {
              const errorMessage = <any>error;

              if (errorMessage != null) {
                const body = JSON.parse(error._body);
                this.errorMessage = body.message;
                console.log(error);
              }
            }
          );
  }*/


  OnSubmit(username, password) {
    this.userService.userAuthentication(username, password).subscribe((res: any) => {
      sessionStorage.setItem('token', res.token);
      location.reload();
      const data = res.json();
      console.log(res);
      this.token = data.token;
      const user: any = JSON.stringify(data.user);
      const company = JSON.stringify(data.user.company);

      if (this.token.length === 0) {
        alert('el token no se ha generado');
      } else {
        sessionStorage.setItem('token', this.token);
        localStorage.setItem('company', company);
        localStorage.setItem('user', user);
        if (user.userType.description === 'MAP') {
          this._router.navigate(['/checkins']);
        } else {
          this._router.navigate(['/dashboard']);
        }
        
      }
    },
      (err: any) => {

        this.isLoginError = true;
    
        try {
            // Intenta convertir el _body a un objeto JSON
            const errorBody = JSON.parse(err._body);
            console.log(errorBody);
            // Verifica si el error es de compañía deshabilitada
            if (err.status === 403) {
                this.alertMessage = "Se requiere actualizar información de pago";
            } else {
                this.alertMessage = "Credenciales Incorrectas!";
            }
        } catch (e) {
            // Si hay un error en el parseo, usa el mensaje por defecto
            console.error("Error al parsear la respuesta del servidor:", e);
            this.alertMessage = "Credenciales Incorrectas!";
        }
      });
  }

  logout() {
    localStorage.removeItem('identity');
    sessionStorage.removeItem('token');
    localStorage.removeItem('company');
    localStorage.clear();

    this.identity = null;
    this.token = null;
    this._router.navigate(['/']);
  }

  fadeIn(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

 /* onSubmitRegister() {
    console.log(this.user_register);

    this._userService.register(this.user_register).subscribe(
      response => {
        const user = response.user;
        this.user_register = user;

        if (!user._id) {
          this.alertRegister = 'Error al registrarse';
        } else {
          this.alertRegister = 'El registro se ha realizado, identificate con ' + this.user_register.username;
          this.user_register = {} as User;
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertRegister = body.message;
          console.log(error);
        }
      }
    );
  }*/
}
