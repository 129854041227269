import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { SidebarManagerService } from '../../../services/sidebar-manager.service';
import { CompaniesService } from '../../../services/companies.service';


@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.css'],
  styles: [`
    :host {
      height: 100%;
      overflow-y: auto;
    }`
  ],
})
export class CompanyAddComponent implements OnInit {

  public form: FormGroup;

  public alert = false;
  constructor(
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder,
    private _companyService: CompaniesService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  addCompany = (): void => {
    const company: any = this.form.getRawValue();
    company.settings = {period: 1};
    this._companyService.create(company).subscribe((res: any) => {
      console.log('company agregada', res);
      setTimeout(() => {
        this._sidebarManager.close('company-add', true);
      }, 1000);
    }, (err: HttpErrorResponse) => console.error('Error al crear', err));
  }

  createForm(): void {
    this.form = this._fb.group({
      company_name:   [''],
      username:   [''],
      phone:   [''],
      password:   [''],
      email:   [''],
    });
  }

  get f() {
    return this.form.controls;
  }

}
