import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private url: string = GLOBAL.url + '/companies';
  constructor(
    private http: HttpClient,
  ) { }

  create(data): Observable<any> {
    return this.http.post(`${GLOBAL.url}/users/register`, data);
  }

  list(): Observable<any> {
    return this.http.get(`${this.url}/list`);
  }

  update(id: string, data: any): Observable<any> {
    return this.http.put(`${this.url}/update/${id}`, data);
  }

  updatePeriod(id: string, period: number): Observable<any> {
    return this.http.put(`${this.url}/update-period/${id}`, { newPeriod: period });
  }

  updateRegion(companyId: string, region: any): Observable<any> {
    return this.http.put(`${this.url}/update-region/${companyId}`, { region });
  }
}
