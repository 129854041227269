import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatPaginator } from '@angular/material';
import * as _ from 'lodash';

import { SidebarManagerService } from '../../../services/sidebar-manager.service';

import { DeleteModalComponent } from '../../../pages/delete-modal/delete-modal.component';
import { PaginatorConfig } from '../../../models/miscellaneous';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppSidebar } from '../../../models/sidebar';
import { CompaniesService } from '../../../services/companies.service';
import { ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  public sidebars: AppSidebar[] = [
    { name: 'company-add',        title: 'Agregar', layout: {
      width: 25, height: 'full'
    }},
    { name: 'company-edit',        title: 'Editar', layout: {
      width: 25, height: 'full'
    }},
  ];

  public paginatorConfig: PaginatorConfig = {
    count: 0,
    page: 0,
    limit: 100,
    label: 'Clientes'
  };

  public pagination = {
    currentPage: 1,
    pageSize: 9
  };

  public companies: any[];
  public selectedCompany: any = {};
  public selected = '';
  public lengtht = 0;
  public activeCompanies = 0;

  constructor(
    private _companyService: CompaniesService,
    private fb: FormBuilder,
    public _sidebarManager: SidebarManagerService,
    public _authService: AuthService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
    this._sidebarManager.setSidebarsList(this.sidebars);
    this.companies = [];
  }

  ngOnInit() {
    this.getCompanies();
    this.createForm();
  }

  getCompanies(): void {
    this._companyService.list().subscribe(res => {
      console.log('Respuesta del servicio:', res);
      if (res.length > 0) {
        this.lengtht = res.length;
        this.companies = res;
        const totalCompanies = this.companies.length;
        const inactiveCompanies = this.companies.filter((c: any) => c.enabled === false).length;
        
        this.activeCompanies = totalCompanies - inactiveCompanies;
        
        console.log(`Total: ${totalCompanies}, Inactivos: ${inactiveCompanies}, Activos: ${this.activeCompanies}`);
        
        this.selectCompany(res[0]);
        this.cdr.detectChanges();
      }
    }, (err: HttpErrorResponse) => console.error('Error al obtener clientes', err));
  }  

  selectCompany(company: any): void {
    const selected = _.cloneDeep(company);
    this.selected = company._id;
    this.selectedCompany = company;
  }

  toggleCompanyStatus(company: any): void {
    const enabled = !company.enabled;
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Deshabilitar Cliente',
        title: '',
        text: `¿Ejecutar accion sobre <strong>#${company.name}</strong>?`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this._companyService.update(company._id, {enabled: enabled}).subscribe(() => this.getCompanies()
        , error => console.error('Error al anular: ', error));
      }
    });
  }

  revokeToken(company: any): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar Token',
        title: '',
        text: `¿Ejecutar accion sobre <strong>#${company.name}</strong>?`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this._authService.revokeToken(company._id).subscribe(() => this.getCompanies()
        , error => console.error('Error al anular: ', error));
      }
    });
  }

  openSidebar = (name: string, data: any): void => {
    this._sidebarManager.open(name, data);
    this._sidebarManager.afterClosed(name).subscribe(() => {
      this.getCompanies();
    });
  }

  addCompany = (company: any) => {
    this._sidebarManager.open('company-add', company);
    this._sidebarManager.afterClosed('company-add').subscribe((res) => res ? this.getCompanies() : '');
  }

  editCompany = (company: any) => {
    this._sidebarManager.open('company-edit', company);
    this._sidebarManager.afterClosed('company-edit').subscribe((res) => res ? this.getCompanies() : '');
  }

  changePage(paginator: MatPaginator) {
    this.paginatorConfig.page = paginator.pageIndex;
    this.getCompanies();
  }

  createForm = (): void => {

  }

}
