import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isVerifyingToken = false;
    private tokenSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(true); // Asume que es válido al inicio

    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthHeader(req)).pipe(
            // 🔹 Verificar el token incluso en respuestas exitosas (200, 304, etc.)
            tap(event => {
                if (event instanceof HttpResponse) {
                    this.verifyToken(); // Verifica el token en todas las respuestas exitosas
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 500) {
                    return this.handleTokenExpiration(req, next);
                }
                return throwError(() => error);
            })
        );
    }

    private verifyToken(): void {
        if (!this.isVerifyingToken) {
            this.isVerifyingToken = true;
            this.tokenSubject.next(null); // Indica que se está verificando

            this.authService.checkToken().subscribe(
                (isValid: boolean) => {
                    this.isVerifyingToken = false;
                    this.tokenSubject.next(isValid);
                    if (!isValid) {
                        this.authService.logout();
                    }
                },
                () => {
                    this.isVerifyingToken = false;
                    this.authService.logout();
                }
            );
        }
    }

    private handleTokenExpiration(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isVerifyingToken) {
            this.isVerifyingToken = true;
            this.tokenSubject.next(null);

            return this.authService.checkToken().pipe(
                switchMap((isValid: boolean) => {
                    this.isVerifyingToken = false;
                    this.tokenSubject.next(isValid);
                    return isValid ? next.handle(this.addAuthHeader(req)) : this.logoutAndThrow();
                }),
                catchError(() => this.logoutAndThrow())
            );
        } else {
            return this.tokenSubject.pipe(
                filter(isValid => isValid !== null),
                take(1),
                switchMap(isValid => (isValid ? next.handle(this.addAuthHeader(req)) : this.logoutAndThrow()))
            );
        }
    }

    private addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
        const token = sessionStorage.getItem('token');
        return token ? req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }) : req;
    }

    private logoutAndThrow(): Observable<never> {
        this.authService.logout();
        return throwError(() => new Error('Sesión expirada.'));
    }
}
