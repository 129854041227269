import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CoffeeFarmer } from '../../../../models/productor';
import { IhcafeVoucher } from '../../../../models/ihcafe-voucher';
import { IhcafeVoucherService } from '../../../../services/ihcafe-voucher.service';
import { ProductorService } from '../../../../services/productor.service';
import * as _ from 'lodash';
import { SettlementService } from '../../../../services/settlement.service';
import { UserService } from '../../../../services/user.service';


@Component({
  selector: 'app-ihcafe-voucher-edit',
  templateUrl: './ihcafe-voucher-edit.component.html',
  styleUrls: ['./ihcafe-voucher-edit.component.css']
})
export class IhcafeVoucherEditComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public harvests = ['2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025'];
  public filteredCoffeefarmers: CoffeeFarmer[] = [];
  private coffeefarmers: CoffeeFarmer[] = [];
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private coffeefarmerVoucherService: IhcafeVoucherService,
    private coffeefarmerService: ProductorService,
    private settlementService: SettlementService,
    private userService: UserService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<IhcafeVoucherEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('voucher to edit: ', this.data);
    this.createForm();
    this.listenerFormValues();
    this.form.patchValue(this.data);
    if (!this.data.total_qq_gold) {
      console.log('si lentra');
      this.form.get('total_qq_gold').setValue(this.data.total_qq / 1.25);
    }
    this.getCoffeefarmers();
    const currentHarvest = this.userService.getCompany().harvest;
    this.form.get('harvest').setValue(currentHarvest);
  }

  ngAfterViewInit() {
    this.autocompleteTrigger.closePanel();
  }

  getCoffeefarmers(): void {
    this.coffeefarmerService.getProductores().subscribe(res => {
      this.filteredCoffeefarmers = res;
      this.coffeefarmers = res;
    });
  }

  getTotal_qqLiquidated(id) {
    const params = {id: id, harvest: this.form.get('harvest').value };
    console.log('params voucher: ', params);
    this.settlementService.getSettlementPerCoffeeFarmerTqq(params).subscribe(res => {
      const total_qq = +res.total_qq.toFixed(2);
      const total_settled = +res.total_net.toFixed(2);
      this.form.get('total_qq').setValue(total_qq);
      this.form.get('total_qq_gold').setValue(total_qq / 1.25);
      this.form.get('total_settled').setValue(total_settled);
    });
  }

  listenerFormValues(): void {
    this.form.get('coffeefarmer').valueChanges.subscribe((value: string) => {
      console.log('value coffeefarmer: ', value);
      const data = this.coffeefarmers
      .filter(coffeefarmer => String(coffeefarmer.name)
        .toLowerCase().includes(value.toLowerCase()));
      this.filteredCoffeefarmers = _.orderBy(data, ['name'], ['asc']);
    });

    this.form.get('total_qq_gold').valueChanges.subscribe((value: number) => {
      this.form.get('total_qq').setValue(value * 1.25);
    });
  }

  selectCoffeefarmer(coffeefarmer: CoffeeFarmer): void {
    console.log('selected: ', coffeefarmer);
    this.getTotal_qqLiquidated(coffeefarmer._id);
    // setTimeout(() => this.form.get('coffeefarmer').setValue(coffeefarmer.name), 800);
    this.form.get('rtn').setValue(coffeefarmer.rtn);
    this.form.get('ihcafe').setValue(coffeefarmer.ihcafe);
    this.form.get('telephone').setValue(coffeefarmer.telephone);
  }

  submit(): void {
    const voucher: IhcafeVoucher = Object.assign(this.data, this.form.getRawValue());
    console.log('voucher: ', voucher);
    voucher.total_qq = this.form.get('total_qq_gold').value * 1.25;
    this.coffeefarmerVoucherService.updateVoucher(voucher._id, voucher).subscribe(response => {
      console.log('Comprobante actualizado: ', response);
      this.dialogRef.close();
    }, err => console.error('Error al actualizar comprobante: ', err));
  }

  createForm(): void {
    this.form = this.fb.group({
      address:          ['', Validators.required],
      date:             [new Date(), Validators.required],
      coffeefarmer:     [null, Validators.required],
      department:       ['', Validators.required],
      farmLocation:     ['', Validators.required],
      farmDepartment:   ['', Validators.required],
      harvest:          ['', Validators.required],
      ihcafe:           ['', Validators.required],
      municipality:     ['', Validators.required],
      farmMunicipality: ['', Validators.required],
      rtn:              ['', Validators.required],
      total_qq:         [0, Validators.required],
      total_settled:    [0, Validators.required],
      total_qq_gold:    [0, Validators.required],
      telephone:        ['', Validators.required],
      voucher:          ['', Validators.required],
      observations:     [''],
    });
  }

}
