import {SharedModule} from './../../shared/shared.module';
import {MatSelectSearchModule} from './../mat-select-search/mat-select-search.module';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyAddComponent } from './company-add/company-add.component';

@NgModule({
  declarations: [
    CompaniesComponent,
    CompanyAddComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatSelectSearchModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class adminModule { }
