import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

import { IhcafeVoucherService } from '../../../../services/ihcafe-voucher.service';
import { ExporterService } from '../../../../services/exporter.service';
import { PrintService } from '../../../../services/print.service';

import { IhcafeVoucherEditComponent } from './../ihcafe-voucher-edit/ihcafe-voucher-edit.component';
import { IhcafeVoucherAddComponent } from '../ihcafe-voucher-add/ihcafe-voucher-add.component';
import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';

import { IhcafeVoucher } from '../../../../models/ihcafe-voucher';
import { Exporter } from '../../../../models/exporter';
import { AppSidebar } from '../../../../models/sidebar';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { SaleVoucher } from '../../../../models/sale-voucher';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../../../services/excel.service';
import * as moment from 'moment';

@Component({
  selector: 'app-ihcafe-voucher',
  templateUrl: './ihcafe-voucher.component.html',
  styleUrls: ['./ihcafe-voucher.component.css']
})
export class IhcafeVoucherComponent implements OnInit {
  public displayedColumns = ['cod', 'date', 'voucher', 'coffeefarmer', 'total_qq', 'total_sold', 'total_net', 'harvest', 'collated', 'action'];
  public dataSource = new TableVirtualScrollDataSource<IhcafeVoucher>();
  public saleVoucher = false;
  public selected: any = {};
  public exporters: Exporter[] = [];
  public exporter: string;
  harvests: string[] = [];
  originalData: IhcafeVoucher[] = []; // Guarda los datos originales
  selectedHarvest: string = '';
  public sidebars: AppSidebar[] = [
    { name: 'sale-voucher-add',        title: 'Agregar comprobante de venta', layout: {
      width: 30, height: 'full'
    }},
  ];
  @ViewChild('selectExport') selectExport;

  constructor(
    private ihcafeVoucherService: IhcafeVoucherService,
    private printService: PrintService,
    public dialog: MatDialog,
    private _excelService: ExcelService,
    public _sidebar: SidebarManagerService,
    private _exporterService: ExporterService,
    ) { }

  ngOnInit() {
    this._sidebar.setSidebarsList(this.sidebars);
    this.getVouchers();
    this.getExporters();
  }

  getExporters = (): void => {
    this._exporterService.getExporters().subscribe((exporters: Exporter[]) => {
      this.exporters = exporters;
    }, (err: HttpErrorResponse) => console.error('Error al obtener exportadores', err));
  }

  getVouchers(): void {
    this.ihcafeVoucherService.getVouchers().subscribe((response: IhcafeVoucher[]) => {
      console.log(response);
      this.originalData = response; // Guardamos la copia original
      this.dataSource.data = response;

      this.harvests = Array.from(new Set(response.map(voucher => voucher.harvest)));

    }, err => console.error('Error al obtener comprobantes de productor: ', err));
  }

  filterByHarvest(): void {
    if (!this.selectedHarvest) {
      this.dataSource.data = [...this.originalData]; // Clonamos la data original
    } else {
      const filtered = this.originalData.filter(voucher => voucher.harvest === this.selectedHarvest);
      this.dataSource.data = filtered.length ? [...filtered] : [];
    }
  }  
  
  openAddVoucherDialog(): void {
    const dialogRef = this.dialog.open(IhcafeVoucherAddComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '700px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getVouchers();
    });
  }

  openEditVoucherDialog(voucher): void {
    const dialogRef = this.dialog.open(IhcafeVoucherEditComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '700px',
      data: voucher
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getVouchers();
    });
  }

  openDialogCancel(voucher: IhcafeVoucher): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular anticipo',
        title: '',
        text: `¿Seguro que quieres anular el comprobante <strong>#${voucher.cod}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        voucher.enabled = false;
        this.ihcafeVoucherService.updateVoucher(voucher._id, voucher).subscribe(response => {
          console.log('Comprobante anulado: ', response);
          this.getVouchers();
        }, err => console.error('Error al actualizar comprobante: ', err));
      }
    });
  }

  selectRow = (voucher: IhcafeVoucher): void => {
    const forSold = voucher.sale.vouchers.length === 0 ? voucher.total_qq / 1.25 : voucher.sale.remaining;
    if (this.saleVoucher) {
      if (voucher._id in this.selected) delete this.selected[voucher._id];
      else {
        if (this.selectedLen > 0 && forSold > 0 && voucher.enabled) {
          this.selected[voucher._id] = Object.assign(_.pick(voucher, ['_id', 'sale', 'coffeefarmer']), {
            total_qq: voucher.sale.vouchers.length === 0 ? (voucher.total_qq / 1.25) : voucher.sale.remaining
          });
        }
      }
    }
    console.log('selected', this.selected);
  }

  exportAsXLSX = () => {
    console.log(this.dataSource.data[0]);
    const rows = this.dataSource.data.map((row: IhcafeVoucher) => ({
      Código: row.cod,
      Fecha: new DatePipe('es-HN').transform(row.date, 'shortDate'),
      Productor: row.coffeefarmer,
      RTN: row.rtn || '',
      'Clave IHCAFE': row.ihcafe || '',
      Quintales: row.total_qq,
      Oro: row.total_qq / 1.25,
      Comprobante: row.voucher,
      Liquidado: row.total_settled,
      Cosecha: row.harvest,
      Observaciones: row.observations,
    }));
    this._excelService.exportAsExcelFile(rows, `Comprobantes de IHCAFE - ${moment(new Date()).format('DD/MM/YYYY')}`);
  }

  print(voucher) {
    console.log('toPrint: ', voucher);
    this.printService.getVoucher(voucher._id)
      .then((result: any) => {
        const blob = new Blob([result], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      });
  }

  openAddSaleVoucher = (): void => {
    this._sidebar.open('sale-voucher-add', Object.values(this.selected));
    this._sidebar.afterClosed('sale-voucher-add').subscribe((response) => {
      if (response) this.getVouchers(), this.selected = {};
    });
  }

  changeStatus = (): void => {
    console.log('status change', this.saleVoucher);
    if (!this.saleVoucher) {
      this.selected = {};
    }
  }

  get selectedLen() {
    return 25 - (Object.keys(this.selected).length);
  }
}
